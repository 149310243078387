import React, { useEffect, useState } from "react";
import "./home.css";
import Button from "../../components/Button";
import DogCarousel from "../../components/DogCarousel";
import DogCard from "../../components/DogCard";
import DogPlate from "../../assets/svg/DogPlate";
import DogToy from "../../assets/svg/DogToy";
import DogBed from "../../assets/svg/DogBed";
import PencilIcon from "../../assets/svg/PencilIcon";
import { getDogs } from "../../service/actions";
import { useTranslation } from "react-i18next";

import LoginModal from "../../components/LoginModal";

const Section3 = () => {
  const { t } = useTranslation();
  const [dogs, setDogs] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  const dogsAction = async () => {
    try {
    const request = await getDogs("", "", "", "name", [], [], []);
    setDogs(request.dogs);
    }
    catch {
      console.log("Dogs not loaded.");
    }
  };

  useEffect(() => {
    dogsAction();
  }, []);

  return (
    <div className="home-section1-3" id="section2">
      <div className="section1-main-content-3">
        <div className="section1-left-3">
          <div className='home-badge'>
            <PencilIcon />
            <span className='home-badge-text'>for authors</span>
          </div>
          <br/>
          <span className="section1-title-3">Your works, on your terms</span>
          <br />
          <br />
          <span className="section1-title2-left">Use our first-of-its-kind <span className='bolder-text'>consent management platform</span> to opt out of GenAI training everywhere. Rest easy while we monitor the web for unlicensed use of your works.</span>

          <div className="section1-buttons">
            <Button
              title={'Sign up for early access'}
              onClick={() => setShowModal(true)}
              filled
            />
          </div>
        </div>

      </div>
      <LoginModal showModal={showModal} handler={setShowModal} createAccount />
    </div>
  );
};

export default Section3;
