import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import News from "../pages/News";
import Article from "../pages/Article";

const routes: any = [
  {
    path: "/",
    element: <Home />,
    title: "Home",
  },
  {
    path: "/*",
    element: <NotFound />,
  },
  {
    path: "/about/:slug",
    element: <Article />
  }
];

export default routes;
