// @ts-nocheck
import React, { useState, useEffect } from "react";
import { login, register } from "../../service/actions";
import { Modal } from "antd";
import { showToast } from "../../utils";
import Button from "../Button";
import Spinner from "../Spinner";
import Input from "../Input";
import "./loginModal.css";
import { useTranslation } from "react-i18next";
import { useParamsNavigate } from '../../utils';

const LoginModal: React.FC<{
  showModal?: boolean;
  handler: Function;
  createAccount?: boolean;
  applyToAdopt?: boolean;
  dogId?: string;
}> = ({ showModal, handler, createAccount, applyToAdopt, dogId }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountCreation, setAccountCreation] = useState(createAccount);

  const navigate = useParamsNavigate();

  useEffect(() => {
    const scriptSrc = "https://tally.so/widgets/embed.js";
    const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

    const loadScript = () => {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      script.onload = () => {
        if (typeof Tally !== "undefined") {
          Tally.loadEmbeds();
        } else {
          document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframe) => {
            iframe.src = iframe.dataset.tallySrc;
          });
        }
      };
      document.body.appendChild(script);
    };

    if (!existingScript) {
      loadScript();
    } else {
      if (typeof Tally !== "undefined") {
        Tally.loadEmbeds();
      }
    }
  }, []);

  const handleRegister = async () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      showToast(t("user.invalidEmail"));
      return false;
    }

    register(email, password)
      .then((result) => {
        if (result.status === 200) {
          setIsDone(true);
          localStorage.setItem("user", JSON.stringify(result?.data.user));
          localStorage.setItem("token", JSON.stringify(result?.data.token));
          setTimeout(() => {
            if (applyToAdopt) {
              navigate(`/adopt/create/${result?.data.user.id}/${dogId}`, {
                state: { email, token: result.data.token },
              });
            } else
              navigate("/onboarding", {
                state: { email, token: result.data.token },
              });
          }, 1500);
        }
      })
      .catch(() => {
        setIsLoading(false);
        showToast(t("user.accountCreationError"));
      });
  };

  const handleLogin = () => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      setIsLoading(false);
      showToast(t("user.invalidEmail"));
      return false;
    }

    const loginUser = async () => {
      try {
        const request = await login(email, password);
        if (request.data) {
          localStorage.setItem("user", JSON.stringify(request?.data.user));
          localStorage.setItem("token", JSON.stringify(request?.data.token));
          setIsDone(true);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        return request.data ? true : false;
      } catch {
        setIsLoading(false);
        showToast(t("user.loginError"));
      }
    };

    loginUser();
  };

  const handleOk = () => {
    handler(false);
  };

  const validateEmail = (email) => {
    // Simple email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCancel = () => {
    handler(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (typeof Tally !== "undefined") {
        Tally.loadEmbeds();
      } else {
        document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframe) => {
          iframe.src = iframe.dataset.tallySrc;
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [showModal]);

  if (accountCreation) {
    return (
      <>
        <Modal
          open={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="modal-root">
            <iframe 
              data-tally-src="https://tally.so/embed/wQrlQ7?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
              loading="lazy" 
              width="100%" 
              height="381" 
              frameBorder="0" 
              marginHeight="0" 
              marginWidth="0" 
              title="Let's chat!"
            />
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Modal
        open={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="modal-root">
          <iframe 
            data-tally-src="https://tally.so/embed/wQrlQ7?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
            loading="lazy" 
            width="100%" 
            height="381" 
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0" 
            title="Let's chat!"
          />
        </div>
      </Modal>
    </>
  );
};

export default LoginModal;