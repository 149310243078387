import axios from 'axios';

export const ipAcquisitionApi = axios.create({
    baseURL: 'https://api.ipify.org/',
});

export const locationApi = axios.create({
    baseURL: 'https://pro.ip-api.com/json',
    params: {
        key: 'eWa7lrSQdNMCA8g',
        fields: 'status,message,country,countryCode,region,regionName,city,zip,lat,lon,timezone,isp,org,as,mobile,query'
    }
});