import React, { useEffect, useState } from 'react';
import { getBlogPostBySlug } from '../../service/actions';
import './article.css';
import { useParams } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { datadogLogs } from "@datadog/browser-logs";
import { Helmet } from 'react-helmet-async';
import Spinner from '../../components/Spinner';



const Article = () => {

    const { slug } = useParams();
    const [post, setPost] = useState<any>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        datadogLogs.logger.info(`Blog post loaded. Slug ${slug}`, { slug });
        const fetchBlogPostBySlug = async () => {
            try {
                const result = await getBlogPostBySlug(slug);
                if (result && result.data) {
                    setPost(result.data.posts[0]);
                    setLoading(false);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchBlogPostBySlug();
        // eslint-disable-next-line
    }, []);

    if(loading) {
        return <></>
    }

    return (
        <>
            <Helmet>
                <title>{`Quenta.ai | Our mission`}</title>
                <meta name="description" content={`${post ? post.excerpt : ""}`} />
            </Helmet>
            <Navbar />
            <div className='article-wrapper'>
                <div className='article-root'>
                    {
                        post &&
                        <>
                            <h1 className='header-title'>{post.title}</h1>
                            <h2 className='subheader'>{post.excerpt}</h2>
                            <img src={post.feature_image} alt='Feature' />
                            <div dangerouslySetInnerHTML={{ __html: post.feature_image_caption }} className='feature-image-caption' />
                            <div dangerouslySetInnerHTML={{ __html: post.html.replaceAll('http://54.91.148.4/', 'http://ghost.petpair.co/').replaceAll('figure', 'div').replaceAll('figcaption', 'div class="article-img-caption"') }} />
                        </>
                    }
                </div>
            </div>
            <Footer />
        </>
    )

};


export default Article;