import React, { useEffect, useState } from "react";
import "./home.css";
import Button from "../../components/Button";
import illustration from "../../assets/images/hero.png";
import ArrowDown from "../../assets/svg/ArrowDown";
import { useTranslation } from "react-i18next"; // Import the video file
import LoginModal from "../../components/LoginModal";


const Section1 = () => {
  const { t } = useTranslation();
  
  const [showModal, setShowModal] = useState(false);

  const [videoSrc, setVideoSrc] = useState('https://videos.petpair.co/desktopvideo.mp4');

  useEffect(() => {
    const handleResize = () => {
      // console.log('resizing', window.innerWidth)
      if (window.innerWidth <= 768) {
        setVideoSrc('https://videos.petpair.co/mobilevideo.mp4');
        console.log('minus', window.innerWidth)
      } else {
        setVideoSrc('https://videos.petpair.co/desktopvideo.mp4');
        console.log('plus', window.innerWidth)
      }
    };

    // Set the initial video source
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log(videoSrc);

  return (
    <div className="home-section1" id="section1">
      <video autoPlay muted loop className="video-background">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="section1-main-content">
        <div className="section1-left">
          <span className="section1-title">Putting power back in authors’ hands.</span><br/><br/>
          <span className="section1-title2">We get authors paid for licensing their works to AI training <span className='bolder-text'>on their terms</span>, and protect from unauthorized uses.</span>
          <div className="section1-buttons">
          <Button
              title={'Sign up for early access'}
              onClick={() => setShowModal(true)}
              filled
            />
          </div>
        </div>
      </div>
      <LoginModal showModal={showModal} handler={setShowModal} createAccount />
    </div>
  );
};

export default Section1;
