import React, { useEffect, useState } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { useParamsNavigate } from '../../utils';
import "./button.css";

const Button: React.FC<{
  filled?: boolean;
  small?: boolean;
  medium?: boolean;
  location?: string;
  title: any;
  onClick?: Function;
  dogSlug?: string;
  id?: any;
  disabled?: boolean;
}> = ({ filled, small, location, title, onClick, dogSlug, medium, id, disabled }) => {
  const navigate = useParamsNavigate();
  const [user, setUser] = useState<any>(null); // should be improved (creating single state for every button)

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user") as any));
  }, []);

  const handleButtonClick = () => {
    (datadogLogs as any).logger.info(`Button clicked. Button: ${title}`, {
      button: { title, dogSlug, clickEvent: location },
      url: window.location.href,
      user,
    });
    (window as any).fbq(
      "track",
      `${title} Button Click`,
      user
        ? {
            name: user.name,
            email: user.email,
            address: user.address,
            dog: dogSlug,
          }
        : { status: "logged out" }
    );
    if (location) {
      if (isExternalURL(location)) {
        if (location.includes("jotform")) window.open(location, "_blank");
        else window.location.href = location;
      } else {
        navigate(location);
      }
    } else if (onClick) {
      onClick();
    }
  };

  const isExternalURL = (url: string) => {
    return /^(https?:\/\/)|(mailto:)|(tel:)/.test(url);
  };

  return (
    <div
      className={`button ${filled ? "button-filled" : "button-normal"} ${
        small && "button-small"
      } ${medium && "button-medium"} ${disabled && 'button-disabled'}`}
      onClick={handleButtonClick}
      id={id}
    >
      {title}
    </div>
  );
};

export default Button;
