import React, { useEffect, useRef } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Section1 from "./Section1";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Button from '../../components/Button';
import bling from '../../assets/images/bling.png';
import expansion from '../../assets/images/expansion.png';
import forum from '../../assets/images/forum.png';
import greatoaks from '../../assets/images/greatoaks.png';
import dividebyzero from '../../assets/images/dividebyzero.png';
import newlin from '../../assets/images/newlin.webp';
import "./home.css";

const Home = () => {

  useEffect(() => {
    document.title = `Quenta.ai`;
    datadogLogs.logger.info("Home page loaded", { url: window.location.href });
  }, []);

  // const carouselRef = useRef<HTMLDivElement>(null);
  // const trackRef = useRef<HTMLUListElement>(null);

  // useEffect(() => {
  //   const track = trackRef.current;

  //   if (track) {
  //     const slides = Array.from(track.children) as HTMLLIElement[];
  //     const totalSlides = slides.length;
  //     const slideWidth = slides[0].getBoundingClientRect().width + 100; // Include the gap

  //     let position = 0;

  //     const startCarousel = () => {
  //       const intervalId = setInterval(() => {
  //         position -= slideWidth;
  //         track.style.transition = 'transform 0.5s ease-in-out';
  //         track.style.transform = `translateX(${position}px)`;

  //         if (Math.abs(position) >= slideWidth * totalSlides) {
  //           setTimeout(() => {
  //             track.style.transition = 'none';
  //             position = 0;
  //             track.style.transform = `translateX(${position}px)`;
  //             // Force reflow to reset the transition
  //             track.offsetHeight;
  //             track.style.transition = 'transform 0.5s ease-in-out';
  //           }, 500); // Match the transition duration
  //         }
  //       }, 3000);

  //       return () => clearInterval(intervalId);
  //     };

  //     const intervalId = startCarousel();

  //     return () => clearInterval(intervalId as any);
  //   }
  // }, []);

  return (
    <div>
      <Navbar />
      <div className="home-root">
        <div className="home-content">
          <Section1 />
          <div className='home-design-interlude'>
            <span className='interlude-title'>We're backed by...</span>
            <br/>
            <br/>
            <div className='carousel'>
              <div className='carousel-track-container'>
                <ul className='carousel-track'>
                  <li className='carousel-slide'><img src={bling} className='logos-interlude' alt="Bling logo" /></li>
                  <li className='carousel-slide'><img src={expansion} className='logos-interlude' alt="Expansion logo" /></li>
                  <li className='carousel-slide'><img src={greatoaks} className='logos-interlude' alt="Great Oaks logo" /></li>
                  <li className='carousel-slide'><img src={forum} className='logos-interlude' alt="Forum logo" /></li>
                  <li className='carousel-slide'><img src={dividebyzero} className='logos-interlude' alt="Divide by zero logo" /></li>
                  <li className='carousel-slide'><img src={newlin} className='logos-interlude' alt="Newlin logo" /></li>
                  {/* Duplicate slides for seamless scrolling */}
                  <li className='carousel-slide'><img src={bling} className='logos-interlude' alt="Bling logo" /></li>
                  <li className='carousel-slide'><img src={expansion} className='logos-interlude' alt="Expansion logo" /></li>
                  <li className='carousel-slide'><img src={greatoaks} className='logos-interlude' alt="Great Oaks logo" /></li>
                  <li className='carousel-slide'><img src={forum} className='logos-interlude' alt="Forum logo" /></li>
                  <li className='carousel-slide'><img src={dividebyzero} className='logos-interlude' alt="Divide by zero logo" /></li>
                  <li className='carousel-slide'><img src={newlin} className='logos-interlude' alt="Newlin logo" /></li>
                  {/* Duplicate slides for seamless scrolling */}
                  <li className='carousel-slide'><img src={bling} className='logos-interlude' alt="Bling logo" /></li>
                  <li className='carousel-slide'><img src={expansion} className='logos-interlude' alt="Expansion logo" /></li>
                  <li className='carousel-slide'><img src={greatoaks} className='logos-interlude' alt="Great Oaks logo" /></li>
                  <li className='carousel-slide'><img src={forum} className='logos-interlude' alt="Forum logo" /></li>
                  <li className='carousel-slide'><img src={dividebyzero} className='logos-interlude' alt="Divide by zero logo" /></li>
                  <li className='carousel-slide'><img src={newlin} className='logos-interlude' alt="Newlin logo" /></li>
                </ul>
              </div>
            </div>
          </div>
          <Section3 />
          <div className='home-design-interlude'>
          <span className='interlude-title'>Everything we build is creator-first.</span>
          <br />
          <br />
          <Button 
          title='Learn more about our mission'
          filled
          location='/about/quenta-ai-mission'
          />
          </div>
          <Section4 />
          {/* <Section5 /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
