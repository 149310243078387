import axios from 'axios';


const ghost = axios.create({
    baseURL: 'https://ghost.petpair.co/ghost/api/content',
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
    params: {
        key: 'acc7527ba69404e48b248e7525', // Replace 'YOUR_API_KEY' with your actual API key
    }
});

export default ghost;