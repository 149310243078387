import "./footer.css";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { useLocation } from "react-router-dom";
import InstagramButton from "../../assets/svg/InstagramButton";
import FacebookButton from "../../assets/svg/FacebookButton";
import WhatsappButton from "../../assets/svg/WhatsappButton";
import whiteLogo from "../../assets/images/white-logo.png";
import { useParamsNavigate } from "../../utils";
import LoginModal from "../../components/LoginModal";


const Footer = (props: any) => {
  const { t } = useTranslation();
  
  const location = useLocation();


  const params = location.search;

  const navigate = useParamsNavigate();


  return (
    <div className="footer" {...props}>
      <div className="footer-bottom">
        <img src={whiteLogo} className='footer-logo'/>
        <div className="footer-menu">
          <ul>
            <li>
              <a href={`/about/quenta-ai-mission`} onClick={() => {
                      datadogLogs.logger.info(`About link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>Our mission</a>
            </li>
            <li>
              <a href={`/#section2`} onClick={() => {
                      datadogLogs.logger.info(`Shelters link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>Authors</a>
            </li>
            <li>
              <a href={`/#section4`} onClick={() => {
                      datadogLogs.logger.info(`Fosters link clicked on the footer.`, {
                        url: window.location.href,
                      });
              }}>Publishers</a>
            </li>
            <li>
              <a href={"mailto:contact@quenta.ai?subject=Talk%20with%20Quenta"} onClick={() => {
                      datadogLogs.logger.info(`Adopters link clicked on the footer.`, {
                        url: window.location.href,
                      });

              }}>Contact us</a>
            </li>
          </ul>
        </div>
        <div className="footer-copyright-disclaimer">
          <span className="footer-copyright-text">
            Copyright © 2024 Quenta
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
