import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Button from "../Button";
import OptionsButton from "../OptionsButton";
import LoginModal from "../LoginModal";
import Spinner from "../Spinner";
import Account from "../../assets/svg/Account";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";
import { useParamsNavigate } from "../../utils";

import "./navbar.css";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({ name: "" });
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const params = location.search;

  const getToken = async () => {
    try {
      const token = await localStorage.getItem("token");
      const user = await JSON.parse(localStorage.getItem("user") || "");
      if (user) {
        setUser(user);
      }
      if (token) setLoggedIn(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    getToken();
  }, [showModal]);

  const navigate = useParamsNavigate();

  const handleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleMenuToggle = () => {
    datadogLogs.logger.info(`Hamburger toggle clicked on the navbar.`, {
      user,
      url: window.location.href,
    });
    setMenuOpen((prevState) => !prevState);
  };

  const options = [
    {
      label: "Logout",
      value: (event: any) => {
        event.stopPropagation();
        datadogLogs.logger.info(`User logged out.`, {
          user,
          url: window.location.href,
        });
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      },
    },
  ];

  return (
    <div className="navbar-wrapper">
      <nav className={`navigation ${menuOpen ? "open" : ""}`}>
        <a className="brand" href={`/${params}`}>
          <img
            src={logo}
            className="logo"
            alt="Logo"
          />
        </a>
        <div className="navigation-menu">
          <ul>
            
            <li>
              <a href={`/about/quenta-ai-mission`} onClick={() => {
                      datadogLogs.logger.info(`Fosters link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>Our&nbsp;mission</a>
            </li>
            <li>
              <a
                style={{ cursor: "pointer" }}
                href='/#section2'
              >
                For&nbsp;authors
              </a>
            </li>
            <li>
              <a href={`/#section4`} onClick={() => {
                      datadogLogs.logger.info(`Blog link clicked on the navbar.`, {
                        user,
                        url: window.location.href,
                      });
              }}>For&nbsp;publishers</a>
            </li>
          </ul>
        </div>

          <div className="navigation-buttons">
            <div className="available-dogs-button">
            <Button
              title={'Sign up for early access'}
              onClick={() => setShowModal(true)}
              filled
              small
            />
            </div>
          </div>
        <div className="menu-toggle" onClick={handleMenuToggle}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <LoginModal showModal={showModal} handler={setShowModal} />
      </nav>
    </div>
  );
};

export default Navbar;
