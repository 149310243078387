import React, { useState } from "react";
import "./home.css";
import Button from "../../components/Button";
import illustration from "../../assets/images/ladystick.png";
import cutbox1 from "../../assets/images/cutbox1.png";
import cutbox2 from "../../assets/images/cutbox2.png";
import cutboxmobile from "../../assets/images/cutboxmobile.png";
import lockcircle from "../../assets/images/lockcircle.png";
import BuildingIcon from "../../assets/svg/BuildingIcon";
import { useTranslation } from "react-i18next";
import LoginModal from "../../components/LoginModal";

const Section4 = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="home-section1-4" id="section4">
      <div className="section1-main-content-4">
        <div className="section1-left-4">
          <div className='home-badge'>
            <BuildingIcon />
            <span className='home-badge-text'>for publishers</span>
          </div>
          <br/>
          <span className="section1-title-4">{"Protect your authors. Defend their work."}</span>
          <br />
          <br/>
            <img src={cutbox1} className='image-grid'/> 
            <img src={cutboxmobile} className='image-grid-mobile'/>    
          <div className="section1-buttons">
          <Button
              title={"Let's chat"}
              onClick={() => setShowModal(true)}
              filled
            />
          </div>
        </div>
      </div>
      <LoginModal showModal={showModal} handler={setShowModal} createAccount />
    </div>
  );
};

export default Section4;
