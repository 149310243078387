import api from './api';
import { ipAcquisitionApi, locationApi } from './locationService';
import { datadogLogs } from "@datadog/browser-logs";
import ghost from './blogService';

export const login = async (email, password) => {
  try {
    const result = await api.post('/login', { email, password });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const register = async (email, password) => {
  try {
    const result = await api.post('/users', { email, password, type: 'PRE_APP' });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const makeProductPayment = async (paymentData) => {
  try {
    const result = await api.post('/product-payment', paymentData);
    return result;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to handle it in the component or caller function
  }
};


export const registerSocial = async (user) => {
  try {
    const result = await api.post('/users', { ...user, type: user.type || 'PRE_APP' });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getFirstProduct = async () => {
  try {
    
    const result = await api.get('/products/first');
    return result.data.product; // Return the product data
  } catch (err) {
    console.error(err);
    return null; // Return null in case of an error
  }
};

export const fetchCalendarAvailability = async (userId) => {
  try {
    const result = await api.get(`/calendar/availability/?userId=${userId}`);
    return result.data;
  } catch (error) {
    console.error('Error fetching availability:', error);
    throw error;
  }
};

// Frontend function to create a calendar event
export const createCalendarEvent = async (eventData, accessToken) => {
  try {
    const result = await api.post('/calendar/create-event', eventData, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    return result.data;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};


export const checkEmailExists = async (email) => {
  try {
    const result = await api.get(`/check-email/${email}`);
    return result.data.exists;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getBlogPosts = async () => {
  try {
    const result = await ghost.get(`/posts/`);
    return result;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getBlogPostBySlug = async (slug) => {
  try {
    const result = await ghost.get(`/posts/slug/${slug}`);
    return result;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loginWithFacebook = async (email) => {
  try {
    const result = await api.post('/facebook/login', { email });
    return result.data;
  } catch (err) {
    console.error(err);
    return { error: "Login with Facebook unavailable" };
  }
};


export const getUserByEmail = async (email) => { 
  const result = await await api.get(`/users/${email}`);
  
  if (result.error) {

    console.error(result.error);
  } else {

    return result;
  }
}



export const getUsers = async (token) => {
  try {
    const result = await api.get('/users', { headers: { Authorization: token } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getDogBySlug = async (slug) => {
  try {
    const result = await api.get('/dog', { params: { slug } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getUploadUrl = async (fileName, token) => {
  try {
    
    const response = await api.get('/upload-url', {
      params: {
        fileName: fileName,
      },
      headers: {
        Authorization: token
      }
    });
    return response.data.signedUrl;
  } catch (err) {
    console.error(err);
  }
};

export const makePayment = async (amount, dogName, cardNumber, cvc, expiry, adoptionId, adopterName) => {
  try {
    const result = await api.post('/payment', { amount, dogName, cardNumber, cvc, expiry, adoptionId, adopterName });
    return result;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to handle it in the component or caller function
  }
};

export const makeAffirmPaymentIntent = async (amount, dogName, adoptionId, adopterName, adopterEmail, day, month, year) => {
  try {
    const result = await api.post('/payment-affirm', { amount, dogName, adoptionId, adopterName, adopterEmail, day, month, year });
    return result;
  } catch (err) {
    console.error(err);
    throw err; // Rethrow the error to handle it in the component or caller function
  }
};

export const createCalendarEventRequest = async (eventData) => {
  try {
    const result = await api.post('/calendar/create-event', eventData);
    return result.data;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const getDogById = async (id) => {
    try {
      const result = await api.get('/dogs/' + id);
      return result;
    } catch (err) {
      console.error(err);
    }
  };

export const savePreference = async (email, preferredAge, preferredTraits, preferredSize, pickupAvailability, dogMustBeGoodWith) => {
  try {
    const result = await api.post('/users-preference', { email, preferredAge, preferredTraits, preferredSize, pickupAvailability, dogMustBeGoodWith });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateUser = async (email, phone, name, location, token) => {
  try {
    const result = await api.put('/users', { email, phone, name, location }, { headers: { Authorization: token } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updatePassword = async (oldPassword, newPassword, userId, token) => {
  try {
    const result = await api.put(`/users/${userId}/password`, { oldPassword, newPassword }, { headers: { Authorization: token } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const editUser = async (user, token) => {
  try {
    const result = await api.post('/users/edit-profile', user, { headers: { Authorization: token } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const addFavorite = async (userId, dogId) => {
  try {
    const result = await api.post('/users/add-favorite', { userId, dogId });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const removeFavorite = async (userId, dogId) => {
  try {
    const result = await api.post('/users/remove-favorite', { userId, dogId });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const subscribeToNewsletter = async (email) => {
  try {
    const result = await api.post('/newsletter-sub', { email });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getDogDimensions = async () => {
  try {
    const result = await api.get('/dog-dimensions');
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

export const getSitemap = async () => {
  try {
    const result = await api.get('/sitemap.xml');
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getDogAttributes = async () => {
  try {
    const result = await api.get('/dog-attributes');
    return result.data;
  } catch (err) {
    console.error(err);
  }
};




export const getAdoption = async (adoptionId, token) => {
    try {
      const result = await api.get(`/adoption/${adoptionId}`);
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

export const getDogs = async (traits, zipCode, ids, sortBy, age, weight, location) => {
  try {
    const result = await api.get('/dogs', {
      params: {
        traits,
        zipCode,
        ids,
        sortBy,
        age: age.toString(),
        weight: weight.toString(),
        location: location.toString()
      }
    });
    return result.data;
  } catch (err) {
    console.error(err);
  }
};

const getUserIp = async () => {
  try {
    const response = await ipAcquisitionApi.get('?format=json');
    return response.data.ip;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getUserLocation = async () => {
  try {
    const ip = await getUserIp();
    if (ip) {
      const response = await locationApi.get('/' + ip);

        datadogLogs.logger.info(`IP Location acquired`, {
          ...response.data
      })
      return response.data;
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const getToken = async () => {
  try {
    const token = await localStorage.getItem('token');
    return token;
  } catch (err) {
    console.error(err);
  }
};

export const requestAdoption = async (adoptionData, token) => {
  try {
    const result = await api.post('/adoption', adoptionData, { headers: { Authorization: token } });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateAdoption = async (adoptionId, adoptionData, token) => {
    try {
      const result = await api.put(`/adoption/${adoptionId}`, adoptionData);
      return result;
    } catch (err) {
      console.error(err);
    }
};

export const createFosterApplication = async (fosterId) => {
  try {
    const result = await api.post('/foster-app', { fosterId });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateFosterApplication = async (adoptionId, data) => {
  try {
    const result = await api.patch(`/foster-app/${adoptionId}`, data);
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const checkPurchaseLimit = async (email, productId) => {
  try {
    const result = await api.post('/check-purchase-limit', { email, productId });
    return result.data; // Return whether the user can purchase more units
  } catch (err) {
    console.error(err);
    return false; // Return false in case of an error
  }
};

export const getProductById = async (productId) => {
  try {
    const result = await api.get(`/products/${productId}`);
    return result.data.product; // Return the product data
  } catch (err) {
    console.error(err);
    return null; // Return null in case of an error
  }
};

export const sendSuggestionToSlack = async (suggestion, email) => {
  try {
    // Make a POST request to the '/suggestions' endpoint
    const result = await api.post('/send-suggestion', { suggestion, email });
    
    // Return the result of the API call
    return result;
  } catch (error) {
    // Log and handle errors if any
    console.error('Error sending suggestion to Slack:', error);
    throw error; // Rethrow the error to handle it in the component or caller function
  }
};
