import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export const age = ["Puppy", "Young", "Adult", "Old"];

export const weight = [
  "Small (<20lbs)",
  "Medium (20-50lbs)",
  "Large (50+ lbs)",
];

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const showToast = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4500, // Duration in milliseconds, set to 0 to disable auto-close
    // className: "custom-toast",
  });
};

export function formatAddress(addressString: string): any {
  const addressParts = addressString.split(',').map(part => part.trim());

  const address: any = {
    country: 'US',
    line1: `${addressParts[0]}, ${addressParts[1]}`, 
    city: addressParts[2] || '', 
    state: addressParts[4] || '', 
    postal_code: addressParts[5] || '' 
  };

  return address;
}


export const formatDescription = (
  description: string,
  dogName: string,
  gender: string
) => {
  const objectPronoun = gender === "MALE" ? "him" : "her";
  const possessivePronoun = gender === "MALE" ? "his" : "her";
  const toBe = gender === "MALE" ? "he is" : "she is";
  const personalPronoun = gender === "MALE" ? "he" : "she";

  return (description || "")
    .replaceAll("{{name}}", dogName)
    .replaceAll("{{to-be}}", toBe)
    .replaceAll("{{possessive-pronoun}}", possessivePronoun)
    .replaceAll("{{object-pronoun}}", objectPronoun)
    .replaceAll("{{personal-pronoun}}", personalPronoun);
};

export const formatDate = (inputDate: string) => {
  // Step 1: Parse the input date string into a JavaScript Date object
  const dateObj = new Date(inputDate);

  // Step 2: Extract the necessary components
  const year = dateObj.getFullYear();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();

  // Step 3: Format the date into the desired output format
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

export const useParamsNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithPreservedParams = (to: any) => {
    // Remove the leading "?" from location.search
    const queryParams = location.search.slice(1);

    // Check if the target URL already contains a "?"
    const hasExistingQuery = to.includes('?');

    // Append the current query parameters to the new URL
    const targetURL = `${to}${hasExistingQuery ? '&' : '?'}${queryParams}`;

    // Navigate to the target URL
    navigate(targetURL);
  };

  return navigateWithPreservedParams;
};


export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const stateAbbreviations = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY",
};

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]



export const DOGCARD_IMAGE_DESKTOP_SIZE='306x310';
export const DOGCARD_IMAGE_MOBILE_SIZE='205x192';
export const PROFILE_PHOTO_DESKTOP_IMAGE_SIZE = '550x504';
export const PROFILE_PHOTO_MOBILE_IMAGE_SIZE = '350x349';